/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */
import React from "react";
import { linkResolver } from "./linkResolver";

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */

const repositoryConfigs = [
  {
    repositoryName: process.env.PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      general_content_page: React.lazy(() =>
        import("./templates/GeneralContentPage")
      ),
    },
  },
];

export default repositoryConfigs;
