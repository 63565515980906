exports.linkResolver = (doc) => {
  console.log("Resolving: ", doc);
  // URL for a page type
  if (doc.type === "page") {
    return `/${doc.uid}`;
  }

  if (doc.type === "general_content_page") {
    return `/${doc.uid}`;
  }
  if (doc.type === "newsroom") {
    return `/newsroom`;
  }

  // Backup for all other types
  return "/";
};
