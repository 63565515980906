import {
  PrismicPreviewProvider,
} from "gatsby-plugin-prismic-previews";
import * as React from "react";
import repositoryConfigs from "./src/prismicPreviews";

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);

const HeadComponents = [
  <script
    key="cognito-script"
    src="https://www.cognitoforms.com/f/seamless.js"
    data-key="ZzIdNcZP10K4STqir9adsA"
  />,
];

export const onRenderBody = (
  { setHeadComponents, setHtmlAttributes },
) => {
  setHeadComponents(HeadComponents);
  setHtmlAttributes({ lang: "en" });
};
